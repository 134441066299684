.chips-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    text-transform: capitalize;
    margin-top: 5px;
    .axl-chip {
        box-shadow: #888 1px 2px 3px;
        font-size: 18px;
    }
    a .axl-chip{
        cursor: pointer;
    }
    a .axl-chip:hover{
        box-shadow: #888 0.5px 1px;
    }
}
.chips-diseases{
    .axl-chip {
        box-shadow: #888 1px 2px 3px;
        font-size: 11px !important;
        height: 20px !important;
    }
}