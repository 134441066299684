.phase-trials-table {
  width: 100%;
  max-width: 800px;
  @media (max-width: 599px) {
    width: 100%;
  }
  .phase-trials-header-row.no-border-radius {
    border-radius: 0px;
  }
  .phase-trials-header-row {
    background-color: #2bb670;
    border-radius: 10px 10px 0px 0px;
    // normal cursor on hover
    cursor: default !important;
    .phase-trials-cell.full-width {
      width: 50%;
      text-align: center;
      padding-left: 28px;
    }
    .phase-trials-cell {
      color: white;
      font-weight: bold;
      text-align: right;
    }
    .phase-trials-cell:nth-child(1) {
      text-align: left;
    }
  }
  .phase-trials-footer-row {
    border-top: 2px solid #555;
    padding-top: 2px;
    .phase-trials-cell {
      font-weight: bold;
    }
  }
  .phase-trials-row,
  .phase-trials-footer-row,
  .phase-trials-header-row {
    width: 100%;
    display: flex;
  }
  .phase-trials-row.appr-subheading {
    // background-color: #c5ecda;
    font-weight: bold;
    border-top: 1px solid white;
  }
  .phase-trials-row.appr-subheading.expanded {
    background-color: #c5ecda;
  }
  .phase-trials-row:hover {
    background-color: #eef6f2;
    cursor: pointer;
  }
  .phase-trials-cell {
    padding: 5px;
    font-size: 18px;
  }
  .phase-trials-cell:nth-child(1) {
    width: 50%;
    padding-left: 10px;
  }
  .phase-trials-cell {
    width: 16%;
  }
}
