h6 {
  font-weight: bold;
  margin-bottom: 0;
  font-size: small;
}

#parentDiv {
  display: flex;
  height: 30px;
  align-items: center;
  margin-bottom: 30px;
}

#switch {
  width: 60px;
  height: 100%;
  border: 1px solid black;
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.switchDot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
}

.gridItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: lightgray;
}

.gridDivider {
  width: 1%;
  height: 100%;
  width: 2px;
  background-color: #000;
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 0;
}

.MuiList-padding {
  padding-top: 0%;
  padding-bottom: 0%;
}
