* {
    box-sizing: border-box;
  }
  body {
    font-size: 14px;
  }
  .v29243_127 {
    width: 860px;
    height: 640px;
    background: rgba(255,255,255,1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
  }
  .v29243_128 {
    width: 767px;
    height: 50px;
    /* background: url("../images/v29243_128.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 113px;
    left: 45px;
    overflow: hidden;
  }
  .v29243_129 {
    width: 767px;
    height: 50px;
    /* background: url("../images/v29243_129.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  .v29243_130 {
    width: 767px;
    height: 50px;
    background: rgba(238,238,238,1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(255,128,158,1);
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    overflow: hidden;
  }
  .v29243_131 {
    width: 568px;
    color: rgba(34,34,34,1);
    position: absolute;
    top: 17px;
    left: 83px;
    font-family: Rubik;
    font-weight: Bold;
    font-size: 14px;
    opacity: 1;
    text-align: center;
  }
  .v29243_133 {
    width: 767px;
    height: 50px;
    /* background: url("../images/v29243_133.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 175px;
    left: 45px;
    overflow: hidden;
  }
  .v29243_134 {
    width: 767px;
    height: 50px;
    /* background: url("../images/v29243_134.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  .v29243_135 {
    width: 767px;
    height: 50px;
    background: rgba(255,128,158,1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    overflow: hidden;
  }
  .v29243_136 {
    width: 674px;
    color: rgba(255,255,255,1);
    position: absolute;
    top: 17px;
    left: 36px;
    font-family: Rubik;
    font-weight: Bold;
    font-size: 14px;
    opacity: 1;
    text-align: center;
  }
  .v29240_264 {
    width: 224px;
    height: 35px;
    /* background: url("../images/v29240_264.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 25px;
    left: 26px;
    overflow: hidden;
  }
  .v29240_265 {
    width: 169px;
    color: rgba(255,128,158,1);
    position: absolute;
    top: 7px;
    left: 55px;
    font-family: Rubik;
    font-weight: Bold;
    font-size: 28px;
    opacity: 1;
    text-align: left;
  }
  .v29240_266 {
    width: 42px;
    height: 35px;
    /* background: url("../images/v29240_266.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  .v29240_267 {
    width: 42px;
    height: 35px;
    background: rgba(255,128,158,1);
    opacity: 0.3199999928474426;
    position: relative;
    top: 0px;
    left: 0px;
    border-radius: 50%;
  }
  .v29240_268 {
    width: 38px;
    height: 31px;
    background: rgba(255,128,158,1);
    opacity: 1;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 50%;
  }
  .v29240_269 {
    width: 25px;
    color: rgba(255,255,255,1);
    position: absolute;
    top: 8px;
    left: 8px;
    font-family: Rubik;
    font-weight: Bold;
    font-size: 26px;
    opacity: 1;
    text-align: center;
  }
  .v29286_85 {
    width: 39px;
    height: 40px;
    /* background: url("../images/v29286_85.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 29px;
    left: 782px;
    overflow: hidden;
  }
  .v29286_86 {
    width: 39px;
    height: 40px;
    background: rgba(198,198,198,1);
    opacity: 0.5;
    position: relative;
    top: 0px;
    left: 0px;
    border-radius: 50%;
  }
  .v29286_87 {
    width: 22px;
    height: 22px;
    background: rgba(255,255,255,1);
    opacity: 1;
    position: absolute;
    top: 8px;
    left: 10px;
    overflow: hidden;
  }
  .v29286_88 {
    width: 15px;
    height: 15px;
    background: rgba(255,255,255,1);
    opacity: 1;
    position: absolute;
    top: 3px;
    left: 3px;
  }
  .name {
    color: #fff;
  }
  .v29287_101 {
    width: 150px;
    height: 114px;
    /* background: url("../images/v29287_101.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 242px;
    left: 89px;
    overflow: hidden;
  }
  .v29287_102 {
    width: 150px;
    height: 114px;
    /* background: url("../images/v29287_102.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  .v29287_103 {
    width: 150px;
    height: 114px;
    /* background: url("../images/v29287_103.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  .v29287_104 {
    width: 146px;
    height: 94px;
    background: rgba(255,128,158,0.15000000596046448);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(255,128,158,1);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
  }
  .v29287_105 {
    width: 77px;
    height: 29px;
    background: rgba(255,255,255,1);
    opacity: 1;
    position: absolute;
    top: 79px;
    left: 36px;
    border: 1px solid rgba(255,128,158,1);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    overflow: hidden;
  }
  .v29287_106 {
    width: 67px;
    color: rgba(34,34,34,1);
    position: absolute;
    top: 86px;
    left: 62px;
    font-family: Rubik;
    font-weight: Medium;
    font-size: 14px;
    opacity: 1;
    text-align: left;
  }
  .v29287_107 {
    width: 150px;
    color: rgba(34,34,34,1);
    position: absolute;
    top: 36px;
    left: 0px;
    font-family: Rubik;
    font-weight: Medium;
    font-size: 14px;
    opacity: 1;
    text-align: center;
  }
  .v29287_108 {
    width: 150px;
    height: 109px;
    /* background: url("../images/v29287_108.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 242px;
    left: 269px;
    overflow: hidden;
  }
  .v29287_109 {
    width: 150px;
    height: 109px;
    /* background: url("../images/v29287_109.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  .v29287_110 {
    width: 150px;
    height: 109px;
    /* background: url("../images/v29287_110.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  .v29287_111 {
    width: 146px;
    height: 94px;
    background: rgba(255,128,158,0.15000000596046448);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(255,128,158,1);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
  }
  .v29287_112 {
    width: 77px;
    height: 29px;
    background: rgba(255,255,255,1);
    opacity: 1;
    position: absolute;
    top: 79px;
    left: 36px;
    border: 1px solid rgba(255,128,158,1);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    overflow: hidden;
  }
  .v29287_113 {
    width: 67px;
    color: rgba(34,34,34,1);
    position: absolute;
    top: 86px;
    left: 62px;
    font-family: Rubik;
    font-weight: Medium;
    font-size: 14px;
    opacity: 1;
    text-align: left;
  }
  .v29287_114 {
    width: 150px;
    color: rgba(34,34,34,1);
    position: absolute;
    top: 36px;
    left: 0px;
    font-family: Rubik;
    font-weight: Medium;
    font-size: 14px;
    opacity: 1;
    text-align: center;
  }
  .v29287_115 {
    width: 150px;
    height: 115px;
    /* background: url("../images/v29287_115.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 241px;
    left: 446px;
    overflow: hidden;
  }
  .v29287_116 {
    width: 150px;
    height: 115px;
    /* background: url("../images/v29287_116.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  .v29287_117 {
    width: 150px;
    height: 115px;
    /* background: url("../images/v29287_117.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  .v29287_118 {
    width: 146px;
    height: 94px;
    background: rgba(255,128,158,0.15000000596046448);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(255,128,158,1);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
  }
  .v29287_119 {
    width: 77px;
    height: 29px;
    background: rgba(255,255,255,1);
    opacity: 1;
    position: absolute;
    top: 79px;
    left: 36px;
    border: 1px solid rgba(255,128,158,1);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    overflow: hidden;
  }
  .v29287_120 {
    width: 69px;
    color: rgba(34,34,34,1);
    position: absolute;
    top: 87px;
    left: 62px;
    font-family: Rubik;
    font-weight: Medium;
    font-size: 14px;
    opacity: 1;
    text-align: left;
  }
  .v29287_121 {
    width: 150px;
    color: rgba(34,34,34,1);
    position: absolute;
    top: 36px;
    left: 0px;
    font-family: Rubik;
    font-weight: Medium;
    font-size: 14px;
    opacity: 1;
    text-align: center;
  }
  .v29287_122 {
    width: 150px;
    height: 114px;
    /* background: url("../images/v29287_122.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 242px;
    left: 626px;
    overflow: hidden;
  }
  .v29287_123 {
    width: 150px;
    height: 114px;
    /* background: url("../images/v29287_123.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  .v29287_124 {
    width: 150px;
    height: 114px;
    /* background: url("../images/v29287_124.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  .v29287_125 {
    width: 146px;
    height: 94px;
    background: rgba(255,128,158,0.15000000596046448);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(255,128,158,1);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
  }
  .v29287_126 {
    width: 77px;
    height: 29px;
    background: rgba(255,255,255,1);
    opacity: 1;
    position: absolute;
    top: 79px;
    left: 36px;
    border: 1px solid rgba(255,128,158,1);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    overflow: hidden;
  }
  .v29287_127 {
    width: 67px;
    color: rgba(34,34,34,1);
    position: absolute;
    top: 86px;
    left: 62px;
    font-family: Rubik;
    font-weight: Medium;
    font-size: 14px;
    opacity: 1;
    text-align: left;
  }
  .v29287_128 {
    width: 150px;
    color: rgba(34,34,34,1);
    position: absolute;
    top: 36px;
    left: 0px;
    font-family: Rubik;
    font-weight: Medium;
    font-size: 14px;
    opacity: 1;
    text-align: center;
  }
  .v29287_129 {
    width: 150px;
    height: 114px;
    /* background: url("../images/v29287_129.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 368px;
    left: 89px;
    overflow: hidden;
  }
  .v29287_130 {
    width: 150px;
    height: 114px;
    /* background: url("../images/v29287_130.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  .v29287_131 {
    width: 150px;
    height: 114px;
    /* background: url("../images/v29287_131.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  .v29287_132 {
    width: 146px;
    height: 94px;
    background: rgba(255,128,158,0.15000000596046448);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(255,128,158,1);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
  }
  .v29287_133 {
    width: 77px;
    height: 29px;
    background: rgba(255,255,255,1);
    opacity: 1;
    position: absolute;
    top: 79px;
    left: 36px;
    border: 1px solid rgba(255,128,158,1);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    overflow: hidden;
  }
  .v29287_134 {
    width: 67px;
    color: rgba(34,34,34,1);
    position: absolute;
    top: 86px;
    left: 62px;
    font-family: Rubik;
    font-weight: Medium;
    font-size: 14px;
    opacity: 1;
    text-align: left;
  }
  .v29287_135 {
    width: 150px;
    color: rgba(34,34,34,1);
    position: absolute;
    top: 36px;
    left: 0px;
    font-family: Rubik;
    font-weight: Medium;
    font-size: 14px;
    opacity: 1;
    text-align: center;
  }
  