.MuiTypography-colorInherit {
  color: black !important;
}

// #homeButtonBreadcrumb {
//   border-radius: 20px;
//   background-color: lightgrey;
//   padding: 5px 10px;
// }

#breadcrumb {
  margin-bottom: 20px;
}

$qn-btn-color: #2bb670;
$qn-btn-sub-color: #525252;
$qn-btn-color-hover: #1e8c4a;
$qn-btn-sub-color-hover: #525252;
.btn-quick-nav {
  background-color: transparent !important;
  border: 2px solid $qn-btn-color !important;
  color: $qn-btn-color !important;
  border-radius: 5px !important;
  margin-right: 5px !important;
  margin-bottom: 20px !important;
  .disabled {
    background-color: #e0e0e0 !important;
    color: #9e9e9e !important;
    border: 2px solid #e0e0e0 !important;
  }
}
.btn-quick-nav.submenu {
  border: 2px solid $qn-btn-sub-color !important;
  color: $qn-btn-sub-color !important;
}
.btn-quick-nav.submenu:hover {
  background-color: $qn-btn-sub-color-hover !important;
}
.btn-quick-nav:hover {
  background-color: $qn-btn-color-hover !important;
  color: white !important;
}
.btn-quick-nav.submenu.active {
  background-color: $qn-btn-sub-color !important;
}
.btn-quick-nav.active {
  background-color: $qn-btn-color !important;
  color: white !important;
}