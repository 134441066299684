.trial-card {
    min-height: 200px;
    width: 100%;
    border: 1px solid black;
    border-radius: 20px;
    color: black;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    .trial-card-header {
        padding: 5px 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid black;
        font-weight: bold;
        background-color: #c5eeaa;
        .trial-header-title {
            font-size: 22px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .trial-header-subtitle {
            font-size: 18px;
        }
    }
    .trial-card-body {
        display: flex;
        font-weight: 400;
        .trial-card-leftbody, .trial-card-rightbody {
            display: flex;
            flex-direction: column;
            padding: 20px;
        }
        .trial-card-leftbody {
            width: 30%;
            background-color: #9fe87020;
        }
        .trial-card-rightbody {
            width: 70%;
            padding-right: 5%;
            border-left: 1px solid;
        }
    }
}

