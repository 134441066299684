.active-trials-table {
  width: 70%;
  max-width: 700px;
  @media (max-width: 599px) {
    width: 100%;
  }
  .active-trials-header-row {
    background-color: #2bb670;
    border-radius: 10px 10px 0px 0px;
    // sticky header
    position: sticky;
    top: 57px;
    z-index: 99;
    // normal cursor on hover
    cursor: default !important;
    .active-trials-cell {
      color: white;
      font-weight: bold;
    }
    .active-trials-cell:nth-child(2) {
      text-align: right;
      margin-right: 24px;
    }
  }
  .active-trials-footer-row {
    border-top: 2px solid #555;
    padding-top: 2px;
    .active-trials-cell {
      font-weight: bold;
    }
  }
  .active-trials-row,
  .active-trials-footer-row,
  .active-trials-header-row {
    width: 100%;
    display: flex;
  }
  .active-trials-row.appr-subheading {
    // background-color: #c5ecda;
    font-weight: bold;
    border-bottom: 1px solid black;
  }
  .active-trials-row.appr-subheading.grouping-row {
    // background-color: #c5ecda;
    border: 1px solid black;
    border-top: none;
  }
  .active-trials-row.expand-row {
    background-color: white;
    padding-left: 10px;
  }
  // .active-trials-row.expand-row.expanded {
  //   background-color: #eef6f2;
  // }
  .active-trials-row.expand-middle-row {
    background-color: #eef6f2;
    padding-left: 20px;
    .active-trials-cell{
      font-size: 20px;
    }
  }
  .active-trials-row.expand-middle-row.expanded {
    background-color: #c5ecda;
    border-bottom: dotted;
  }
  .active-trials-row.leaf-row {
    background-color: white;
    padding-left: 50px;
    .active-trials-cell{
      font-size: 18px;
    }
  }
  .active-trials-row:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .active-trials-cell {
    padding: 8px;
    font-size: 22px;
  }
  .active-trials-cell:nth-child(1) {
    width: 70%;
    padding-left: 10px;
  }
  .active-trials-cell:nth-child(2) {
    width: 30%;
    margin-right: 24px;
  }
}
