.flexTop20 {
  display: flex;
  margin-top: 20px;
}

.mt20 {
}

.halfWidthColumn {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.alignSelfCenter {
  align-self: center;
}

.verticalLine {
  height: 20px;
  width: 1px;
  background-color: #000;
  align-self: center;
}

.halfWidthHorizontalLine {
  width: 50%;
  background-color: #000;
  height: 1px;
  align-self: center;
}

.justifyAround {
  display: flex;
  justify-content: space-around;
}

.w45 {
  width: 45%;
}

.width75Column {
  width: 75%;
  display: flex;
  flex-direction: column;
}

.width25Column {
  width: 25%;
  display: flex;
  flex-direction: column;
}

.width75HorizontalLine {
  width: 67%;
  background-color: #000;
  height: 1px;
  align-self: center;
}

.w25 {
  width: 25%;
}

.justifyBetween {
  display: flex;
  justify-content: space-between;
}

.justifyEvenly {
  display: flex;
  justify-content: space-evenly;
}
